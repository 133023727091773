.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #9a9a9a;
  width: 100%;
  /*  z-index: 1; */
}

.multiRange input[type="range"] {
  height: 0px !important;
}

.multiRange input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #547445;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #547445;
  cursor: pointer;
  z-index: 20;
}

/* All the same stuff for Firefox */
.multiRange input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  border: 1px solid #547445;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #547445;
  cursor: pointer;
  z-index: 20;
}

/* All the same stuff for IE */
.multiRange input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  border: 1px solid #547445;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #547445;
  cursor: pointer;
  z-index: 20;
}

.slider__left-value,
.slider__right-value {
  /*  color: #dee2e6; */
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

/* .thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
} */

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #547445;
  /*  background-color: #f1f5f7; */
  border: none;
  border-radius: 50%;
  /*  box-shadow: 0 0 1px 1px #ced4da; */
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #547445;
  /*  background-color: #f1f5f7; */
  border: none;
  border-radius: 50%;
  /*  box-shadow: 0 0 1px 1px #ced4da; */
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.thumb--zindex-5 {
  z-index: 5;
}
