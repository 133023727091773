/* ================================================================== */
/* Toolbars
/* ================================================================== */
.leaflet-draw-section {
  position: relative;
}
.leaflet-draw-toolbar {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.leaflet-draw-toolbar-top {
  margin-top: 0;
  margin-top: 55px;
}
.leaflet-draw-toolbar-notop a:first-child {
  border-top-right-radius: 0;
}
.leaflet-draw-toolbar-nobottom a:last-child {
  border-bottom-right-radius: 0;
}
.leaflet-draw-toolbar a {
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 0.5rem !important;
  -webkit-border-radius: 0.5rem !important;
  -moz-border-radius: 0.5rem !important;
  -ms-border-radius: 0.5rem !important;
  -o-border-radius: 0.5rem !important;
  background-color: rgba(0, 0, 0, 0.707) !important;
  border-bottom: none !important;
}
.leaflet-draw-toolbar a:hover {
  background-color: rgba(0, 0, 0, 0.789) !important;
}

.leaflet-draw-draw-polyline {
  background-image: url("/images/pen.svg");
  background-size: 1.3rem !important;
  background-position: center !important;
}
.leaflet-draw-draw-polygon {
  background-image: url("/images/polygon.svg");
  background-size: 1.3rem !important;
  background-position: center !important;
}

.leaflet-draw-draw-rectangle {
  background-image: url("/images/rectangle.svg");
  background-size: 1.3rem !important;
  background-position: center !important;
}

.leaflet-draw-draw-circle {
  background-image: url("/images/circle1.svg");
  background-size: 1.3rem !important;
  background-position: center !important;
}

.leaflet-draw-draw-marker {
  background-image: url("/images/marker.svg");
  background-size: 1.3rem !important;
  background-position: center !important;
}

.withoutResizeAndDelete .leaflet-draw-edit-edit {
  display: none !important;
}
.withoutResizeAndDelete .leaflet-draw-edit-remove {
  display: none !important;
}

.leaflet-draw-edit-edit {
  background-image: url("/images/resize.svg");
  background-size: 1.3rem !important;
  background-position: center !important;
}

.leaflet-draw-edit-remove {
  background-image: url("/images/eraser.svg");
  background-size: 1.3rem !important;
  background-position: center !important;
}

.leaflet-draw:nth-child(1)
  .leaflet-draw-section:nth-child(2)
  .leaflet-draw-toolbar
  a:nth-child(1) {
  background-image: url("/images/resize.svg");
  background-size: 1.3rem !important;
  background-position: center !important;
}
.leaflet-draw:nth-child(1)
  .leaflet-draw-section:nth-child(2)
  .leaflet-draw-toolbar
  a:nth-child(2) {
  background-image: url("/images/eraser.svg");
  background-size: 1.3rem !important;
  background-position: center !important;
}

.leaflet-retina {
  /* background-image: url("images/spritesheet-2x.png"); */
  /* url("images/spritesheet.svg"); */
}
.leaflet-draw-draw-marker {
  display: hidden !important;
}
.leaflet-draw a {
  display: block;
  text-align: center;
  text-decoration: none;
}
.leaflet-draw a .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  background-color: #b94a48;
}
/* ================================================================== */
/* Toolbar actions menu
/* ================================================================== */
.leaflet-draw-actions {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 26px; /* leaflet-draw-toolbar.left + leaflet-draw-toolbar.width */
  top: 0;
  white-space: nowrap;
}
.leaflet-touch .leaflet-draw-actions {
  left: 50px;
}
.leaflet-right .leaflet-draw-actions {
  right: 26px;
  left: auto;
}
.leaflet-touch .leaflet-right .leaflet-draw-actions {
  right: 50px;
  left: auto;
}

.leaflet-draw-actions li {
  display: inline-block;
}

.leaflet-draw-actions li:first-child a {
  border-left: none;
}
.leaflet-draw-actions li:last-child a {
  border-radius: 0 4px 4px 0;
}

.leaflet-draw-actions li a[title="Cancel drawing"] {
  background-image: url("/images/close.svg");
  background-size: 1.4rem !important;
  background-position: center !important;
  background-repeat: no-repeat;
  margin: 0 0.5rem;
  text-indent: 100rem;
  overflow: hidden;
}
a[title="Clear all layers"] {
  background-image: url("/images/delete.svg") !important;
  background-size: 1.5rem !important;
  background-position: center !important;
  background-repeat: no-repeat;
  margin: 0 0.5rem;
  text-indent: 100rem;
  overflow: hidden;
}
a[title="Finish drawing"] {
  background-image: url("/images/save.svg") !important;
  background-size: 1.4rem !important;
  background-position: center !important;
  background-repeat: no-repeat;
  margin: 0 0.5rem;
  text-indent: 100rem;
  overflow: hidden;
}
a[title="Save changes"] {
  background-image: url("/images/save.svg") !important;
  background-size: 1.4rem !important;
  background-position: center !important;
  background-repeat: no-repeat;
  margin: 0 0.5rem;
  text-indent: 100rem;
  overflow: hidden;
}
a[title="Cancel editing, discards all changes"] {
  background-image: url("/images/close.svg") !important;
  background-size: 1.4rem !important;
  background-position: center !important;
  background-repeat: no-repeat;
  margin: 0 0.5rem;
  text-indent: 100rem;
  overflow: hidden;
}
/* a[title="Draw a marker"]:active {
  visibility: hidden !important;
} */
a[title="Delete last point drawn"] {
  background-image: url("/images/undo.svg") !important;
  background-size: 1.4rem !important;
  background-position: center !important;
  background-repeat: no-repeat;
  margin: 0 0.5rem;
  text-indent: 100rem;
  overflow: hidden;
}
.leaflet-right .leaflet-draw-actions li:first-child a {
  border-radius: 4px 0 0 4px;
}
a[title="Draw a marker"] {
  display: none !important;
}
a[title="Draw a marker"]:active,
a[title="Cancel drawing"] {
  display: none !important;
}
a[title="Cancel drawing"] {
  display: block !important;
}
.leaflet-draw-actions a {
  background-color: rgba(0, 0, 0, 0.707) !important;
  color: #fff;
  font: 11px/19px "Helvetica Neue", Arial, Helvetica, sans-serif;
  line-height: 28px;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  word-break: break-all !important;
  height: 3rem !important;
  width: 3rem;
  border-radius: 0.5rem !important;
  margin: 0 0.5rem;
}
.leaflet-draw-actions a:hover {
  background-color: rgba(0, 0, 0, 0.789) !important;
}
.leaflet-touch .leaflet-draw-actions a {
  font-size: 12px;
  line-height: 30px;
  height: 30px;
}

.leaflet-draw-actions-bottom {
  margin-top: 0;
}

.leaflet-draw-actions-top {
  margin-top: 1px;
}

.leaflet-draw-actions-top a,
.leaflet-draw-actions-bottom a {
  height: 27px;
  line-height: 27px;
}

.leaflet-draw-actions a:hover {
  background-color: #a0a098;
}

.leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
  height: 26px;
  line-height: 26px;
}

/* ================================================================== */
/* Draw toolbar
/* ================================================================== */

.leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: -2px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: 0 -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -31px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -29px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -62px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -60px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -92px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -90px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -122px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -120px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -273px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -271px -1px;
}

/* ================================================================== */
/* Edit toolbar
/* ================================================================== */

/* .leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -152px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -150px -1px;
} */

/* .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -182px -2px;
}
 */
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -180px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -212px -2px;
}
.leaflet-marker-icon.leaflet-interactive {
  width: 25px !important;
  height: 25px !important;
  margin-left: -7px !important;
  margin-top: -7px !important;
}

/* .location .leaflet-marker-icon.leaflet-interactive {
  width: 32px !important;
  height: 32px !important;
  margin-left: -7px !important;
  margin-top: -7px !important;
} */

.leaflet-div-icon {
  background-color: #f1c95e !important;
  border: 4px solid white !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
path[stroke="#3388ff"] {
  stroke: #ffffff;
  stroke-width: 3;
  stroke-linecap: butt;
  stroke-dasharray: 0;
  fill: #ffffff;
}
.leaflet-draw-guide-dash {
  background-color: #ffffff !important;
}

/* .leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -210px -1px;
} */

/* .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -242px -2px;
}

.leaflet-touch
  .leaflet-draw-toolbar
  .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -240px -2px;
}
 */
/* ================================================================== */
/* Drawing styles
/* ================================================================== */

.leaflet-mouse-marker {
  background-color: #fff;
  cursor: crosshair;
}

.leaflet-draw-tooltip {
  background: rgb(54, 54, 54);
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  font: 12px/18px "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin-left: 20px;
  margin-top: -21px;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  z-index: 6;
}

.leaflet-draw-tooltip:before {
  border-right: 6px solid black;
  border-right-color: rgba(0, 0, 0, 0.5);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: "";
  position: absolute;
  top: 7px;
  left: -7px;
}

.leaflet-error-draw-tooltip {
  background-color: #f2dede;
  border: 1px solid #e6b6bd;
  color: #b94a48;
}

.leaflet-error-draw-tooltip:before {
  border-right-color: #e6b6bd;
}

.leaflet-draw-tooltip-single {
  margin-top: -12px;
}

.leaflet-draw-tooltip-subtext {
  color: #f8d5e4;
}

.leaflet-draw-guide-dash {
  font-size: 1%;
  opacity: 0.6;
  position: absolute;
  width: 5px;
  height: 5px;
}
/* .leaflet-draw-edit-edit {
  display: none !important;
} */
/* ================================================================== */
/* Edit styles
/* ================================================================== */

.leaflet-edit-marker-selected {
  background-color: rgba(254, 87, 161, 0.1);
  border: 4px dashed rgba(254, 87, 161, 0.6);
  border-radius: 4px;
  box-sizing: content-box;
}

.leaflet-edit-move {
  cursor: move;
}

.leaflet-edit-resize {
  cursor: pointer;
}

/* ================================================================== */
/* Old IE styles
/* ================================================================== */

.leaflet-oldie {
  border: none !important;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
}
/* .leaflet-control-layers-list {
  background-color: rgba(0, 0, 0, 0.707) !important;
  padding: 0 !important;
  margin: 0 !important;
}
.leaflet-control-layers-list:hover {
  background-color: rgba(0, 0, 0, 0.789) !important;
} */
.leaflet-control-layers-base {
  background-color: rgba(0, 0, 0, 0.131) !important;
  color: white !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0.5rem !important;
}
.leaflet-control-layers-baser:hover {
  background-color: rgba(0, 0, 0, 0.789) !important;
}
.leaflet-control-layers-toggle {
  /* background-color: rgba(0, 0, 0, 0.707) !important; */
  color: white !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 0.5rem !important;
  -webkit-border-radius: 0.5rem!;
  -moz-border-radius: 0.5rem!;
  -ms-border-radius: 0.5rem!;
  -o-border-radius: 0.5rem!;
  background-image: url("/images/layers.svg") !important;
}
.leaflet-control-layers-toggle:hover {
  background-color: rgba(0, 0, 0, 0.789) !important;
}

.leaflet-control-layers-base > label > div {
  /* background-color: rgba(0, 0, 0, 0.576) !important; */
  padding: 1rem 2rem !important;
  margin: 0 !important;
  position: relative !important;
  text-align: center !important;
}
.leaflet-control-layers-base > label > div:hover {
  background-color: rgba(0, 0, 0, 0.576) !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
}
.leaflet-control-layers-selector {
  background-color: rgba(0, 0, 0, 0.707) !important;
  position: absolute !important;
  visibility: hidden !important;
  display: none !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.leaflet-control-layers-selector:checked {
  background-color: rgba(0, 0, 0, 0.707) !important;
}
.leaflet-control-layers-expanded {
  background-color: rgba(0, 0, 0, 0.296) !important;
  margin: 10px !important;
  padding: 0 !important;
  position: relative !important;
}
.leaflet-control-layers {
  background-color: rgba(0, 0, 0, 0.707) !important;
}

.leaflet-control-geosearch form {
  box-shadow: none !important;
}
.leaflet-geosearch-button.active .leaflet-bar-part {
  height: 26px !important;
}
.geosearch .leaflet-bar .leaflet-bar-part a {
  height: 26px !important;
}
.leaflet-control-geosearch a.reset {
  height: 26px !important;
}

/* .leaflet-control-geosearch form input {
  background-color: #00000018 !important;
  height: 39px !important;
}
.leaflet-control-geosearch form input {
  background-color: #00000018 !important;
  height: 39px !important;
}
.leaflet-control-geosearch form .reset {
  background-color: #00000018 !important;
  background-size: 1rem !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  font-size: 18px !important;
}



.leaflet-bar-part .leaflet-bar-part-single {
  display: none !important;
}
.leaflet-control-geosearch form .reset:hover {
  background-color: #00000018 !important;
}
 */
/* .leaflet-touch .leaflet-bar a:last-child:hover {
}
.leaflet-touch .leaflet-bar a:last-child::before {
  background-color: #fbfbfb !important;
}
.leaflet-touch .leaflet-bar a:last-child::after {
  background-color: #fbfbfb !important;
}
.leaflet-control-geosearch form input {
  color: black;
  padding: 10px !important;
}
.leaflet-control-geosearch {
  border-top: 1px solid #555 !important;
  background-color: #fbfbfb !important;
}
 */
/* .leaflet-div-icon {
  margin-left: -5px !important;
  margin-top: -5px !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background: #f1c95e !important;
  /* border: 1px solid #f1a55e; */
/* 
.leaflet-interactive:hover {
  stroke: #f1ca5e;
  fill: #f15ee5;
}
.leaflet-draw-guide-dash {
  background: #f1ca5e !important;
  border-radius: 50%;
}
*/
/* .farm-polygon {
  stroke: #f1ca5e;
  fill: #f1ca5e41;
} */

.request-popup .leaflet-popup-content-wrapper {
  background-color: #547445b8;
  color: white;
  padding: 0.5rem 0.7rem !important;
}
.request-popup .leaflet-popup-content-wrapper:hover {
  background-color: #547445ce;
  color: white;
}
.request-popup .leaflet-popup-tip {
  background-color: #547445b8 !important;
}

.request-popup .leaflet-tooltip-tip:hover {
  background-color: #547445ce !important;
}
/*  ///////////////////////////////////////////////*/
.request-tooltip .leaflet-tooltip-content-wrapper {
  background-color: #547445;
  color: white;
  padding: 0.5rem 0.7rem !important;
}
.request-tooltip .leaflet-tooltip-content-wrapper:hover {
  background-color: #547445;
  color: white;
  padding: 0.5rem 0.7rem !important;
}
.request-tooltip .leaflet-tooltip {
  background-color: #547445b8 !important;
  padding: 0.5rem 0.7rem !important;
}

.request-tooltip .leaflet-tooltip:hover {
  padding: 0.5rem 10rem !important;
  background-color: #547445 !important;
}
.leaflet-tooltip {
  background-color: #547445b6 !important;
  padding: 1.5rem !important;
  border: none !important;
  color: white !important;
  border-radius: 20px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.popup .leaflet-tooltip {
  background-color: #547445b6 !important;
  padding: 1rem !important;
  border: none !important;
  color: white !important;
  border-radius: 20px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/*/////////////*/
/* .request-polygon {
  fill: #5e5e5e77;
} */
.leaflet-interactive:hover {
  fill: #547445e1;
}
.leaflet-popup-close-button {
  width: 1.1rem !important;
  height: 1.1rem !important;
  font-size: 1rem !important;
  margin: 0.4rem;
  padding-left: 0.16rem !important;
  padding-top: 0.15rem !important;
  text-align: center !important;

  line-height: 10;
  /* display: flex !important; */
  /* align-items: center !important; */
  /* justify-content: center !important; */
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}
.leaflet-popup-close-button:hover {
  background-color: #fff !important;
  color: #555 !important;
}
.try-this {
  fill: black !important;
  /* color: black; */
  /* background-color: black; */
}
/*//////////////////////////////////////////////////////*/
.leaflet-control-layers-base {
  background-color: rgba(0, 0, 0, 0.131) !important;
  color: white !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0.5rem !important;
  display: hidden !important;
}
.leaflet-control-layers-baser:hover {
  background-color: rgba(0, 0, 0, 0.789) !important;
  display: hidden !important;
}
.leaflet-control-layers-toggle {
  /*background-color: rgba(0, 0, 0, 0.707) !important;
  */
  color: white !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 2rem !important;
  -webkit-border-radius: 2rem !important;
  -moz-border-radius: 2rem !important;
  -ms-border-radius: 2rem !important;
  -o-border-radius: 2rem !important;
  background-image: url("/images/layers.svg") !important;
  display: hidden !important;
}
.leaflet-control-layers-toggle:hover {
  background-color: rgba(0, 0, 0, 0.789) !important;
  display: hidden !important;
}

.leaflet-control-layers-base > label > div {
  /* background-color: rgba(0, 0, 0, 0.576) !important; */
  padding: 1rem 2rem !important;
  margin: 0 !important;
  position: relative !important;
  text-align: center !important;
  display: hidden !important;
}
.leaflet-control-layers-base > label > div:hover {
  background-color: rgba(0, 0, 0, 0.576) !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
  -webkit-border-radius: 0.5rem !important;
  -moz-border-radius: 0.5rem !important;
  -ms-border-radius: 0.5rem !important;
  -o-border-radius: 0.5rem !important;
  display: hidden !important;
}
.leaflet-control-layers-selector {
  background-color: rgba(0, 0, 0, 0.707) !important;
  position: absolute !important;
  visibility: hidden !important;
  display: none !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: none !important;
}
.leaflet-control-layers-selector:checked {
  background-color: rgba(0, 0, 0, 0.707) !important;
  display: none !important;
}
.leaflet-control-layers-expanded {
  background-color: rgba(0, 0, 0, 0.296) !important;
  margin: 10px !important;
  padding: 0 !important;
  position: relative !important;
  display: none !important;
}
.leaflet-control-layers {
  background-color: rgba(0, 0, 0, 0.707) !important;
  display: none !important;
}

.extract {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.707);
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.extracts {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.707);
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.extract:hover {
  background-color: rgba(0, 0, 0, 0.789);
}

.leaflet-draw-tooltip {
  visibility: hidden !important;
}
.map:hover .leaflet-draw-tooltip {
  visibility: visible !important;
}
