@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Baloo-regular";
  src: url("../assets/fonts/Baloo2-Regular.ttf");
  font-display: swap;
  format: ("ttf");
}
@font-face {
  font-family: "Almarai";
  src: url("../assets/fonts/Almarai-Regular.ttf");
  font-display: swap;
  format: ("ttf");
}

main {
  width: calc(100% - 6rem);
}

::-webkit-scrollbar {
  width: 0;
  scrollbar-width: unset;
}
.show-scroll-bar::-webkit-scrollbar {
  width: 5px;
  scrollbar-width: unset;
  z-index: 20000;
}
.overscroll-y-overlay {
  overflow-y: overlay;
}

@layer components {
  .timeline-point-users {
    @apply w-6 h-6 font-semibold text-center text-white transition duration-200 transform border-2 rounded-full shadow-md outline-none cursor-pointer sm:w-7 sm:h-7 hover:shadow-lg hover:scale-110 focus:outline-none;
  }
  .timeline-dashedBar-users {
    @apply relative h-0 p-0 border-t-4;
  }
  .timeline-straightLine-users {
    @apply absolute h-0 transition-all duration-500 transform rounded bg-dark-gray;
  }
  .activated-dashedBar-users {
    @apply relative h-0 p-0 border-t-4 border-gray-400 border-dashed rounded-md;
  }
  .activated-straightLine-users {
    @apply relative h-0 p-0 bg-black border-t-4 border-black rounded-md;
  }
  .step-one {
    @apply w-6 h-6 font-semibold text-center text-white transition duration-200 transform bg-black border-2 border-black rounded-full shadow-md outline-none cursor-pointer sm:w-7 sm:h-7 hover:shadow-lg hover:scale-110 focus:outline-none;
  }
  .stepper-line {
    @apply relative w-6 h-0 p-0 border-2 border-black rounded-md left-6 bottom-4 sm:w-20;
  }
  .step-selected {
    @apply w-6 h-6 font-semibold text-center text-black transition duration-200 transform border-4 border-black rounded-full shadow-md outline-none cursor-pointer sm:w-7 sm:h-7 hover:shadow-lg hover:scale-110 focus:outline-none;
  }
  .stepper-dashed-line {
    @apply relative bottom-0 w-6 h-0 p-0 border-2 border-gray-500 border-dashed rounded-md -left-2 sm:w-20;
  }
  .stepper-solid-line {
    @apply relative bottom-0 w-6 h-0 p-0 border-2 border-black border-solid rounded-md -left-2 sm:w-20;
  }
  .step-further {
    @apply w-6 h-6 font-semibold -ml-3.5 text-center text-white transition duration-200 transform border-2 rounded-full shadow-md  outline-none cursor-pointer bg-gray-500 border-gray-500 sm:w-7 sm:h-7 hover:shadow-lg hover:scale-110 focus:outline-none;
  }
  .field-label {
    @apply h-8 pt-1 pl-2 text-base font-bold text-gray-500 bg-yellow-400 rounded-lg font-baloo;
  }
  .right-side-parent {
    @apply;
  }
  .triangleBottomLeft {
    @apply text-mako bg-white-smoke-darker;
  }

  .btn-Activ {
    @apply px-3 py-1 text-white rounded-md rounded-bl-none rounded-br-none bg-main;
  }
  .btn-disable {
    @apply px-3 py-1 rounded-md rounded-bl-none rounded-br-none text-light-black bg-white-smoke-light;
  }
  .right-side-menu-item {
    @apply relative flex items-center justify-center h-12 transition   rounded-lg cursor-pointer /* bg-opacity-70 hover:bg-opacity-80 */;
  }
  .right-side-sensors-tabs {
    @apply relative flex items-center justify-center w-auto gap-2 px-3 py-2 text-sm cursor-pointer;
  }
  .right-side-sensors-tabs-tooltip {
    @apply absolute hidden px-2 py-1 text-xs text-center text-white capitalize rounded-md bg-vampire-black bg-opacity-70 group-hover:block top-10;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  font-family: "Baloo-regular";
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.tet {
  display: flex;
  justify-content: flex-e;
}
.boxshadow1 {
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);
}
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}
/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}
.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.btn-color {
  background: #c0533a;
  border-radius: 6px;
  width: 132px;
  height: 42px;
  color: #ffffff;
  margin-left: 30px;
  border: 0px;
  font-weight: 500;
}
.farm-leaflet.leaflet-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow-x: hidden;
}
/* changing custom scroll bar */
/* width and height*/
.form-wrap::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
.form-wrap::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 5px;
}

/* Handle */
.form-wrap::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
.form-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card-wrap::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
/* Track */
.card-wrap::-webkit-scrollbar-track {
  background: #f7f7f7;
  width: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* Handle */
.card-wrap::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
/* Handle on hover */
.card-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.street-wrap::-webkit-scrollbar {
  width: 10px;

  height: 10px;
}
/* Track */
.street-wrap::-webkit-scrollbar-track {
  background: #00000033;
  width: 10px;
  cursor: pointer;
}

/* Handle */
.street-wrap::-webkit-scrollbar-thumb {
  background: #4b4949;
}
/* Handle on hover */
.street-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.satalite-wrap::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
.satalite-wrap::-webkit-scrollbar-track {
  background: #00000033;
  width: 10px;
}

/* Handle */
.satalite-wrap::-webkit-scrollbar-thumb {
  background: #807e7e;
}
/* Handle on hover */
.satalite-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type="radio"]:after {
  width: 18px;
  height: 18px;
  border-radius: 15px;
  top: -2px;
  left: -5px;
  position: relative;
  background: #ffffff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #aaaaaa;
  box-sizing: border-box;
}

input[type="radio"]:checked:after {
  width: 18px;
  height: 18px;
  border-radius: 15px;
  top: -2px;
  left: -5px;
  position: relative;
  content: "";
  background: #ffffff;
  display: inline-block;
  border: 2px solid #547345;
  box-sizing: border-box;
}

.btn_farm {
  display: inline-block;
  width: 148px;
  height: 44px;
  background: #c0533a;
  color: #ffffff;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  border: 0px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.rbc-allday-cell {
  height: auto !important;
}
.tree-farm-stepper .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-family: "Baloo-regular";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}
.tree-farm-stepper .MuiStepConnector-lineHorizontal {
  border-top-style: dashed;
  border-top-width: 2px;
}
.MuiStepConnector-line-active {
  display: block;
  border-color: #010101 !important;
}

.RFS-StepButton {
  width: 31px !important;
  height: 31px !important;
  background: #737373 !important;
}
.RFS-Connector {
  border-color: #737373 !important;
  border-top-style: dashed !important;
  border-top-width: 4px !important;
}

.RFS-ConnectorContainer {
  left: calc((-83% + 1.5em) - 12px) !important;
  right: calc((83% + 1.5em) - 7px) !important;
  top: calc((1.5em - -1px) / 2) !important;
}
.RFS-Connector-d15.active {
  border-color: rgb(1 1 1) !important;
  border-top-style: solid !important;
}

.RFS-Label {
  margin-top: 10px !important;
  margin-bottom: 10px;
  text-align: left !important;
  font-size: 14px;
  line-height: 15px;
  font-family: "Baloo-regular";
  color: #737373;
  font-weight: 600 !important;
}
.RFS-StepMain {
  align-items: flex-start !important;
}
.RFS-StepButton-d16.completed {
  background-color: rgb(1 1 1) !important;
}
.RFS-StepButton.active {
  background-color: #f2ca5f !important;
  border: 4px solid;
}
.RFS-StepContainer {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.RFS-StepButton.completed {
  background-color: #010101 !important;
}
.RFS-Connector.completed,
.RFS-Connector.active {
  border-top-style: solid !important;
  border-color: #010101 !important;
}

.RFS-LabelContainer {
  margin-left: -10px;
}
.RFS-StepButton.completed ~ .RFS-LabelContainer span,
.RFS-StepButton.active ~ .RFS-LabelContainer span {
  color: #010101 !important;
}
.farm-field-stepper {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}
.RFS-StepperContainer {
  width: 105% !important;
}

.range-value {
  position: absolute;
  top: 15%;
}

.range-value span {
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #0f0e0e;
  color: #fff;
  font-size: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-40%, 0);
  border-radius: 6px;
}
.range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #0f0e0e;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media (min-width: 1600px) {
  .RFS-ConnectorContainer {
    left: calc((-85% + 1.5em) - 12px) !important;
    right: calc((85% + 1.5em) - 7px) !important;
    top: calc((1.5em - -1px) / 2) !important;
  }
}

input[type="range"] {
  background: #a0a0a0; /* Otherwise white in Chrome */
  height: 2px;
}

input[type="range"]::-ms-track {
  background: #a0a0a0;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /*   border: 3px solid #ffd953; */
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #4d4d4d;
  cursor: pointer;
  z-index: 20;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  /*   border: 3px solid #ffd953; */
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #4d4d4d;
  cursor: pointer;
  z-index: 20;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  /*   border: 3px solid #ffd953; */
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #4d4d4d;
  cursor: pointer;
  z-index: 20;
}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #43e5f7;
  height: 20px;
}
input[type="range"]::-moz-range-track {
  background-color: #9a905d;
  height: 20px;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
  height: 20px;
}
input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
  height: 20px;
}

.cerate-farm-fom {
  display: grid;
  grid-template-areas:
    "time-line map"
    "form map";
  grid-template-columns: 60% 40%;
  grid-template-rows: 130px 1fr;
}
.time-lien-area {
  grid-area: time-line;
}
.map-area {
  grid-area: map;
}
.form-area {
  grid-area: form;
}

.triangleBottomLeft::after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 50px solid #f5f5f5;
  border-right: 24px solid transparent;
  position: absolute;
  bottom: 0;
  z-index: 0;
}
.styleArrowIconPageTitle:last-child {
  display: none;
}
@media (max-width: 1224px) {
  .triangleBottomLeft::after {
    display: none;
  }
}
.slide-in {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@-webkit-keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(110%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(110%);
  }
}
.react-datepicker__close-icon:dir(ltr) {
  right: 0 !important;
  top: 0rem !important;
}
.react-datepicker__close-icon:dir(rtl) {
  left: 0 !important;
  top: 0rem !important;
}

.ChartComponents line.recharts-cartesian-axis-tick-line,
.ChartComponents line.recharts-cartesian-axis-line {
  stroke: #fff !important;
}

.z-1000 {
  z-index: 1000;
}
.rbc-toolbar {
  font-weight: bold !important;
  font-size: 20px !important;
}
.rbc-header {
  padding: 10px 0 !important;
  font-size: 20px !important;
}
.rbc-date-cell {
  color: #6e6e6e !important;
  text-align: left !important;
  padding-left: 5px !important;
}
.rbc-today {
  background: none !important;
}
.rbc-date-cell.rbc-now a {
  background-color: #547445 !important;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: white;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rbc-day-bg {
  background: none !important;
}
/* .rbc-row {
  height: 40px !important;
} */
/* .rbc-event-content {
  background-color: #ececec;
  color: black;
  padding: 3px;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.rbc-event-content:hover {
  background-color: #ececec56;
} */
.rbc-event-content {
  color: black;
  font-weight: bold;
}
.rbc-event-allday {
  background: none !important;
}
.rbc-event:focus {
  outline: none;
}
.rbc-background-event:focus {
  outline: none;
}
.rbc-day-slot:focus {
  outline: none;
}
.rbc-row-segment {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.AboutUs {
  background-image: url(../assets/img/AboutHeader.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.Header {
  background-image: url(../assets/img/landing/Navbar.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 70vh;
}

.packagesHeader {
  background: rgb(168, 216, 233);
  background: linear-gradient(
    70deg,
    rgba(168, 216, 233, 1) 0%,
    rgba(220, 243, 251, 1) 22%,
    rgba(234, 226, 252, 1) 68%,
    rgba(220, 243, 251, 1) 81%,
    rgba(168, 216, 233, 1) 100%
  );
}

.resourcesHeader {
  background-image: url(../assets/img/landing/resources/Navbar.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}

.alfalfairrigationHeader {
  background-image: url(../assets/img/learningZone/firstHeader.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}
.alfalfaserviceHeader {
  background-image: url(../assets/img/learningZone/secondHeader.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}

.alfalfafertilizationHeader {
  background-image: url(../assets/img/learningZone/thirdHeader.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}

.alfalfainsectdiseasesHeader {
  background-image: url(../assets/img/learningZone/fourthHeader.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}
.alfalfaHeader {
  background-image: url(../assets/img/learningZone/fiveHeader.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}

.firsBlogHeader {
  background-image: url(../assets/img/landing/resources/firstBlog/Navbar.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}
.digitizeFarmProductionHeader {
  background-image: url(../assets/img/landing/resources/digitizeFarmProduction/header.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}

.howMuchIrrigateHeader {
  background-image: url(../assets/img/landing/resources/howMuchIrrigate/howMuchIrrigate.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}

.VariableRateTechniquesHeader {
  background-image: url(../assets/img/landing/resources/VariableRateTechniques/VariableRateTechniques.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
}

@media (max-width: 500px) {
  .footer-info {
    display: none;
  }
  .footer-photos {
    display: none;
  }
  .footer-logo {
    width: 190px;
    margin: auto;
  }
  .footer-backed {
    display: none;
  }
  .footer-sections {
    display: none;
  }
  .footer-rights {
    font-size: 14px;
    text-align: center;
  }
}
@media (max-width: 634px) {
  .footer-info {
    display: none;
  }
  .footer-photos {
    display: none;
  }
  .footer-logo {
    width: 190px;
    margin: auto;
  }
  .footer-backed {
    display: none;
  }
  .footer-sections {
    display: none;
  }
  .footer-rights {
    font-size: 14px;
    text-align: center;
  }
}
.farms-farms {
  background-image: url(../assets/img/ForestFarms.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.farms-pivot {
  background-image: url(../assets/img/PivotsFarms.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.farms-palms {
  background-image: url(../assets/img/PalmsFarms.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.toshkalakes {
  background-image: url(../assets/img/image32.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.react_time_range__time_range_container {
  padding: 5% 0 !important;
  height: 70px !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

.react_time_range__track__disabled {
  z-index: 1;
  border-left: none !important;
  border-right: none !important;
  background-color: #547445 !important;
  width: calc(4.34783% - -1px) !important;
}
.react_time_range__handle_wrapper,
.react_time_range__handle_wrapper,
.react_time_range__handle_marker__error,
.react_time_range__handle_container,
.react_time_range__handle_container,
.react_time_range__tick_marker__large,
.react_time_range__tick_marker {
  display: none !important;
}
.react_time_range__track {
  left: 0 !important;
  width: 0px !important;
  background-color: none !important;
  border-left: none !important;
  border-right: none !important;
}
.react_time_range__tick_label {
  color: white !important;
  margin-top: -45px !important;
  display: none !important;
}
.react_time_range__rail__inner {
  background: #b9593e !important;
  border-bottom: none !important;
}

.gradient {
  background: rgb(18, 111, 73);
  background: linear-gradient(
    90deg,
    rgba(18, 111, 73, 1) 0%,
    rgba(250, 255, 0, 1) 50%,
    rgba(255, 0, 0, 1) 100%
  );
}

.gradient-1 {
  background: rgb(255, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(229, 252, 0, 1) 20%,
    rgba(16, 149, 87, 1) 40%,
    rgba(16, 149, 87, 1) 60%,
    rgba(229, 252, 0, 1) 80%,
    rgba(255, 0, 0, 1) 100%
  );
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background: none !important;
}
.rbc-event:focus,
.rbc-day-slot:focus .rbc-background-event:focus {
  outline: none !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: black;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 15px !important;
  font-weight: bold !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 15px !important;
  font-weight: bold !important;
}

@media (max-width: 1559px) {
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 12px !important;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 12px !important;
  }
}
summary {
  display: flex;
  justify-content: space-between;
}

summary::after {
  margin-left: 1ch;
  display: inline-block;
  font-size: 20px;
  content: "+";
  font-weight: "bold";
}

details[open] > summary::after {
  content: "-";
  font-size: 20px;
  font-weight: "bold";
}
details[open] summary div {
  color: #1b804e;
}
.search:focus {
  outline: none;
}

.logsSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logsDetails summary::after {
  margin-left: 1ch;
  display: inline-block;
  content: url("../assets/svg/arrow-down.svg");
  cursor: pointer;
}

.logsDetails[open] > .logsSummary::after {
  content: url("../assets/svg/arrow-Up.svg");
  cursor: pointer;
}

.search:focus {
  outline: none;
}

.rbc-month-view {
  background-color: white !important;
}

.rbc-btn-group {
  background-color: white !important;
}

.loader {
  width: 80px;
  height: 80px;
  border: 10px solid #fff;
  border-bottom-color: #547445;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.glass {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.9px);
  -webkit-backdrop-filter: blur(0.9px);
}

.reportImage img {
  height: 194px;
}

.Toastify__progress-bar--success {
  background-color: #547445 !important;
}

.Toastify__toast-icon svg {
  fill: #547445 !important;
}

.delete {
  display: none !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-slider .ant-slider-dot {
  width: 0px !important;
  height: 0px !important;
  background-color: #ffffff;
  border: 0px solid #f0f0f0 !important;
}
:where(.css-dev-only-do-not-override-k83k30).ant-slider .ant-slider-track {
  background-color: #547445 !important;
}
:where(.css-dev-only-do-not-override-k83k30).ant-slider
  .ant-slider-handle::after {
  background-color: #547445 !important;
  box-shadow: 0 0 0 2px #547445 !important;
}

.ant-tooltip {
  z-index: 2000px;
}
:where(.css-dev-only-do-not-override-k83k30).ant-tooltip .ant-tooltip-inner {
  min-width: 30px;
  height: 5px !important;
  display: flex !important;
  justify-content: center !important;
  color: white !important;
  align-items: center !important;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 20px;
  font-size: 10px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
:where(.css-dev-only-do-not-override-k83k30).ant-tooltip-placement-top
  .ant-tooltip-arrow {
  top: -7%;
}
.react-datepicker-popper {
  z-index: 5000 !important;
}
.react-datepicker__close-icon {
  display: none !important;
}

.hidehorizontalScroll::-webkit-scrollbar:horizontal {
  display: none;
}

.recharts-area-dots circle {
  stroke-width: 9px;
}
.recharts-label {
  font-weight: bold;
  font-size: 16px;
}

.WeAreGrowingEveryday {
  background-image: url(../assets/img/about/WeAreGrowingEveryday/backGround.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.Agri-Innovation {
  background-image: linear-gradient(#f7f7f7, #ffffff);
}

.contact {
  background: linear-gradient(
    177.66deg,
    rgba(240, 246, 232, 0) -15.36%,
    #f0f6e8 52.94%,
    rgba(240, 246, 232, 0) 113.22%
  );
}

.homePageHeader {
  background: linear-gradient(
    145.46deg,
    rgba(246, 247, 245, 0.164706) -4.63%,
    #f0f6e8 49.79%,
    rgba(247, 247, 247, 0) 102.18%
  );
}

.links::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #547445;
  transition: width 0.3s;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.links:hover::after {
  width: 100%;
}

.react-slideshow-container .default-nav {
  display: none !important;
}

.scrollbar::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #afafaf;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #535353;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #535353;
}

.recharts-layer,
.recharts-cartesian-axis-tick tspan {
  font-family: "Almarai";
  font-size: 12px;
}
.marquee {
  overflow: hidden;
}
.marquee-content {
  display: flex;
  gap: 20px;
  -webkit-animation: scrolling 25s linear infinite;
  animation: scrolling 25s linear infinite;
  justify-content: center;
  align-items: center;
}

.marquee-item {
  flex: 0 0 10vw;
  margin: 0 1vw;
}

.marquee-item img {
  display: block;
  width: 100%;
}

@-webkit-keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}
.css-1p3m7a8-multiValue {
  display: none !important;
}
.datePicker {
  width: 100%;
}
input[type="time"] {
  flex-flow: row-reverse;
  justify-content: flex-end;
  width: 100%;
  gap: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../assets/img/time.png") no-repeat;
  position: relative;
  left: 0;
  margin-top: -13px;
}
